export const panelText = [
  {
    heading: 'Filter out noise. Follow Signals.',
    subtext: 'Content consumption is inefficient, but it doesn’t have to be.',
  },
  {
    heading: 'Information from everywhere—in one place',
    subtext:
      'Save your time and concentration. Stream data from social, markets, and news straight to your boards.',
  },
  {
    heading: 'Take control of your feeds',
    subtext:
      'Filter by keywords, sources, accounts, and more to make sure you only see what you care about.',
  },
  {
    heading: 'Find what you care about, then dive deeper',
    subtext:
      'See historical context, top replies, or the full article—all without leaving the app',
  },
  {
    heading: 'Learn from others',
    subtext: 'See how people in the know stay in the know.',
  },
]

/*
Landing page.

*/

// import React, { useEffect } from 'react'

import React, { ReactElement } from 'react'
import { Carousel } from 'react-responsive-carousel'

import {
  Box,
  Button,
  HStack,
  Heading,
  Text,
  VStack,
  Spacer,
  Image,
  Divider,
  Grid,
  GridItem,
  IconButton,
  ImageProps,
} from '@chakra-ui/react'

import { useAuthModalContext } from '../../context/auth-modal/context'

import { panelText } from '../../utils/copy/landingPageCopy'
import { GrTwitter } from 'react-icons/gr'

import { FaDiscord } from 'react-icons/fa'
import { GrFormPrevious, GrFormNext } from 'react-icons/gr'

import LandingPageImage from './LandingPageImage'
//const HEADER_HEIGHT = 72 // px
const HEADING_FONT_SIZE = 40

const imageProps: ImageProps = {
  borderRadius: 'lg',
  shadow: 'lg',
  w: ['100%', '90%', '80%', '60%', '100%', '100%'],
}

const PrevArrow = (
  clickHandler: () => void,
  hasPrev: boolean,
  label: string
) => {
  return (
    <IconButton
      icon={<GrFormPrevious />}
      aria-label={label}
      isRound
      shadow="md"
      onClick={clickHandler}
      isDisabled={!hasPrev}
      pos="absolute"
      top="45%"
      left="-4rem"
      zIndex={1000}
    />
  )
}

const NextArrow = (
  clickHandler: () => void,
  hasNext: boolean,
  label: string
) => {
  return (
    <IconButton
      icon={<GrFormNext />}
      aria-label={label}
      isRound
      shadow="md"
      onClick={clickHandler}
      isDisabled={!hasNext}
      pos="absolute"
      top="45%"
      right="-4rem"
      zIndex={1000}
    />
  )
}

const LandingPage = (): ReactElement => {
  const { openAuthModal } = useAuthModalContext()

  return (
    <Box h="100vh" w="100%">
      <HStack pos="fixed" p={4} w="100%" verticalAlign={'middle'} bg="#ffffff">
        <HStack verticalAlign="middle">
          <Image
            alt="Logo"
            src="logoIconBlack.svg"
            h={6}
            mt={'3px'}
            mr={'-1px'}
          />
          <Text fontSize={22} fontWeight="medium">
            Signalist
          </Text>
          <Box
            h="100%"
            borderWidth="1.5px"
            borderRadius="md"
            py={0}
            px={2}
            borderColor="#A9A9A9"
          >
            <Text fontSize="xs" color="#A9A9A9" fontWeight="medium">
              beta
            </Text>
          </Box>
        </HStack>
        <Spacer />
        <Button
          bg="brand.purple"
          color="white"
          _hover={{
            bg: 'brand.purpleMedium',
          }}
          onClick={() => openAuthModal('signUp')}
        >
          Sign Up
        </Button>
        <Button
          color="brand.purple"
          borderColor="brand.purple"
          _hover={{ bg: 'brand.purpleLight' }}
          onClick={() => openAuthModal('logIn')}
          variant="outline"
        >
          Log In
        </Button>
      </HStack>

      <VStack spacing={'5%'} pt={'72px'} h="100%">
        <Grid templateColumns="repeat(3, 1fr)" gap={10} maxW="1400px" p={10}>
          <GridItem
            colSpan={[3, 3, 3, 3, 1, 1]}
            w="100%"
            h="100%"
            display="flex"
            justifyContent={'center'}
          >
            <VStack
              align={'flex-start'}
              h="100%"
              w={['100%', '90%', '80%', '60%', '100%', '100%']}
            >
              <Spacer />
              <Heading fontSize={60}>{panelText[0].heading}</Heading>
              <Text>{panelText[0].subtext}</Text>
              <Spacer />
            </VStack>
          </GridItem>
          <GridItem
            colSpan={[3, 3, 3, 3, 2, 2]}
            w="100%"
            display="flex"
            justifyContent={'center'}
          >
            <LandingPageImage src="landingPage/main.png" alt="main-graphic" />{' '}
          </GridItem>
        </Grid>
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          maxW="1300px"
          justifyContent={'center'}
          p={10}
        >
          <GridItem
            colSpan={[3, 3, 3, 3, 1, 1]}
            w="100%"
            h="100%"
            display="flex"
            justifyContent={'center'}
          >
            <VStack
              align={'flex-start'}
              h="100%"
              w={['100%', '90%', '80%', '60%', '100%', '100%']}
            >
              <Spacer />
              <Heading fontSize={HEADING_FONT_SIZE}>
                {panelText[1].heading}
              </Heading>
              <Text>{panelText[1].subtext}</Text>
              <Spacer />
            </VStack>
          </GridItem>

          <GridItem
            colSpan={[3, 3, 3, 3, 2, 2]}
            w="100%"
            display="flex"
            justifyContent={'center'}
          >
            <LandingPageImage
              src="landingPage/panel_1.gif"
              alt="Scrolling on a dashboard"
              {...imageProps}
            />
          </GridItem>
        </Grid>{' '}
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          maxW="1300px"
          justifyContent={'center'}
          p={10}
        >
          <GridItem
            colSpan={[3, 3, 3, 3, 1, 1]}
            w="100%"
            h="100%"
            display="flex"
            justifyContent={'center'}
          >
            <VStack
              align={'flex-start'}
              h="100%"
              w={['100%', '90%', '80%', '60%', '100%', '100%']}
            >
              <Spacer />
              <Heading fontSize={HEADING_FONT_SIZE}>
                {panelText[2].heading}
              </Heading>
              <Text>{panelText[2].subtext}</Text>
              <Spacer />
            </VStack>
          </GridItem>

          <GridItem
            colSpan={[3, 3, 3, 3, 2, 2]}
            w="100%"
            display="flex"
            justifyContent={'center'}
          >
            <LandingPageImage
              src="landingPage/panel_3.gif"
              alt="main-graphic"
              {...imageProps}
            />
          </GridItem>
        </Grid>
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          maxW="1300px"
          justifyContent={'center'}
          p={10}
        >
          <GridItem
            colSpan={[3, 3, 3, 3, 1, 1]}
            w="100%"
            h="100%"
            display="flex"
            justifyContent={'center'}
          >
            <VStack
              align={'flex-start'}
              h="100%"
              w={['100%', '90%', '80%', '60%', '100%', '100%']}
            >
              <Spacer />
              <Heading fontSize={HEADING_FONT_SIZE}>
                {panelText[3].heading}
              </Heading>
              <Text>{panelText[3].subtext}</Text>
              <Spacer />
            </VStack>
          </GridItem>

          <GridItem
            colSpan={[3, 3, 3, 3, 2, 2]}
            w="100%"
            display="flex"
            justifyContent={'center'}
          >
            <Box
              w={['100%', '90%', '80%', '60%', '100%', '100%']}
              pos="relative"
              px={'3rem'}
            >
              <Carousel renderArrowNext={NextArrow} renderArrowPrev={PrevArrow}>
                <div>
                  <LandingPageImage
                    src="landingPage/stocks.gif"
                    alt="Stocks widget view"
                    {...imageProps}
                  />
                </div>
                <div>
                  <LandingPageImage
                    src="landingPage/reddit.gif"
                    alt="Reddit widget view"
                    {...imageProps}
                  />
                </div>
                <div>
                  <LandingPageImage
                    src="landingPage/news.gif"
                    alt="News widget view"
                    {...imageProps}
                  />
                </div>
              </Carousel>{' '}
            </Box>
          </GridItem>
        </Grid>
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          maxW="1300px"
          justifyContent={'center'}
          p={10}
        >
          <GridItem
            colSpan={[3, 3, 3, 3, 1, 1]}
            w="100%"
            h="100%"
            display="flex"
            justifyContent={'center'}
          >
            <VStack
              align={'flex-start'}
              h="100%"
              w={['100%', '90%', '80%', '60%', '100%', '100%']}
            >
              <Spacer />
              <Heading fontSize={HEADING_FONT_SIZE}>
                {panelText[4].heading}
              </Heading>
              <Text>{panelText[4].subtext}</Text>
              <Spacer />
            </VStack>
          </GridItem>

          <GridItem
            colSpan={[3, 3, 3, 3, 2, 2]}
            w="100%"
            display="flex"
            justifyContent={'center'}
          >
            <LandingPageImage
              src="landingPage/panel_4.gif"
              alt="main-graphic"
              {...imageProps}
            />
          </GridItem>
        </Grid>
        <Divider w="95vw" />
        <VStack spacing={3} py={5}>
          <Image alt="Logo" src="logoIconBlack.svg" h={6} mt={'3px'} />
          <Heading fontSize={HEADING_FONT_SIZE}>Try Signalist today</Heading>
          <Text>Quieter consumption starts now.</Text>
          <Spacer />
          <Button
            bg="brand.purple"
            color="white"
            _hover={{
              bg: 'brand.purpleMedium',
            }}
            onClick={() => openAuthModal('signUp')}
          >
            Sign up for free{' '}
          </Button>
        </VStack>
        <Divider w="95vw" />
        <HStack h="10rem" align={'flex-start'} w="95vw" p={3} pb={10}>
          <Image alt="Logo" src="logoIconBlack.svg" h={6} mt={'9px'} />
          <Spacer />
          <IconButton
            aria-label="Twitter: @signalistco"
            as="a"
            target="_blank"
            href={'https://twitter.com/signalistco'}
            variant={'ghost'}
          >
            <GrTwitter fontSize={24} />
          </IconButton>

          <IconButton
            aria-label="Twitter: @signalistco"
            as="a"
            href="https://discord.gg/QwkYwNCF"
            target="_blank"
            variant={'ghost'}
          >
            <FaDiscord fontSize={24} />
          </IconButton>
        </HStack>
      </VStack>
    </Box>
  )
}

export default LandingPage

/*
Landing page image
TODO: fade in on first scroll into view. Pass scrollPos as prop to 
LandingPageImage component and determien whether in view from there
*/

// import React, { useEffect } from 'react'

import React, { ReactElement, useRef, useState, useEffect } from 'react'

import { Image, ImageProps } from '@chakra-ui/react'
import { useOnScreen } from '../../utils/hooks/useOnScreen'

const LandingPageImage = ({ alt, ...props }: ImageProps): ReactElement => {
  const ref = useRef(null)

  // Considered onScreen if more
  // than 300px of element is visible.
  const onScreen = useOnScreen(ref, '-300px')

  const [hasComeOnScreen, setHasComeOnScreen] = useState(true)

  useEffect(() => {
    // if (onScreen && !hasComeOnScreen) {
    setHasComeOnScreen(true)
    // }
  }, [])

  return (
    <Image
      ref={ref}
      alt={alt}
      {...props}
      opacity={onScreen || hasComeOnScreen ? '100%' : '0%'}
      borderRadius="lg"
      shadow="lg"
      w={['100%', '90%', '80%', '60%', '100%', '100%']}
      transition="opacity 500ms linear"
    />
  )
}

export default LandingPageImage

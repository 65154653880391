/*
Landing page.

Behavior:
- Forwards authed users to /home
- Opens sign in or sign up modals depending on query params
*/

// import React, { useEffect } from 'react'
import { AuthAction, withAuthUser } from 'next-firebase-auth'
import { useRouter } from 'next/router'
import React from 'react'
import { useEffect } from 'react'
import Head from 'next/head'
import LandingPage from '../components/landing-page/LandingPage'

import { useCurrentUser } from '../context/user/context'
import { routes } from '../utils/constants'

interface PageParams {
  hasCode: boolean
}
const Index = () => {
  const { user } = useCurrentUser()
  const router = useRouter()

  useEffect(() => {
    if (user != undefined) {
      router.push(routes.home)
    }
  }, [user, router])

  return (
    <>
      <Head>
        <title>Signalist</title>
        <meta
          name="description"
          content="Aggregate, organize, and filter information from all over the web."
        />
        <meta property="og:title" content="Signalist" />
        <meta
          property="og:description"
          content="Aggregate, organize, and filter information from all over the web."
        />
        <meta property="og:url" content="signalist.co" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:site" content="@signalistco" />
      </Head>
      <LandingPage />
    </>
  )
}

export default withAuthUser<PageParams>({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenUnauthedBeforeInit: AuthAction.RETURN_NULL,
  whenUnauthedAfterInit: AuthAction.RENDER,
})(Index)

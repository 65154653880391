/**
 * Hook for checking if an element is on the screen
 * modified from https://usehooks.com/useOnScreen/
 *  */
import { useState, useEffect, MutableRefObject } from 'react'

// Hook
export function useOnScreen(
  ref: MutableRefObject<HTMLDivElement | null>,
  rootMargin = '0px'
): boolean {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false)
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin,
      }
    )
    const current = ref.current
    if (current != null) {
      observer.observe(current)
    }
    return () => {
      if (current != null) {
        observer.unobserve(current)
      }
    }
  }, [ref, rootMargin]) // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting
}
